import { Helmet } from 'react-helmet-async';
import styled, { keyframes } from 'styled-components'



function Products({ sWidth }) {


  return (
    <>
    <Helmet>
        <meta name="description" content="Unlock the potential of your digital strategy with our advanced solutions. Discover a variety of tools designed to elevate your marketing and SEO efforts for outstanding results."/>
        <title>Explore Our Solutions | Advanced Tools for Digital Success</title>
        <link rel="canonical" href="https://improwth.com/products" />
    </Helmet>

    <Container sWidth={sWidth}>
        <h1>Our Products</h1>

        <Card>
            <h2>MarketLab</h2>

            <p>
            MarketLab is a platform that offers comprehensive market research tools, including keyword research, competition research, and backlink analysis. Additionally, it provides site performance and optimization tools, search engine tools, and site testing tools to help you achieve your business goals.
            </p>

            <div>
            <button onClick={()=> window.open('https://marketlab.improwth.com', '_blank')}>Go to Market Lab</button>
            <button onClick={()=> window.location.href = '/market-lab/docs'}>Docs</button>
            </div>
        </Card>
    </Container>
    </>
  )
}

export default Products



const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 100px;
    height: ${props=> props.sWidth > 768? '100vh': '520px'};
    padding-left: ${props=> props.sWidth > 768? 45: 0}px;
    @media (max-width: 768px) {
        justify-content: center;
        align-items: center;
        padding-top: 30px !important;
    }

    > h1 {
        font-family: 'Crimson Text';
        font-weight: 300;
        margin-top: 20px;
        margin-bottom: 25px;
    }
`


const FadeEffect = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`


const Card = styled.div`
    display: flex;
    flex-direction: column;
    width: 300px;
    height: fit-content;
    border: 1px solid lightgray;
    border-radius: 3px;
    box-shadow: 1px 1px 20px lightgray;
    gap: 25px;
    padding: 10px 15px 15px 15px;

    opacity: 0;
    animation: ${FadeEffect} 0.4s ease-in-out forwards;

    > h2 {
        font-family: 'Crimson Text';
        background-image: linear-gradient(to right, darkblue -20%, crimson 90%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        font-weight: 300;
        font-size: 35px;

        > span {
            font-family: 'Crimson Text';
            font-size: 35px;
            font-weight: 300;
            color: black;
        }

        > .MuiSvgIcon-root {
            font-size: larger;
        }
    }

    > p {
        font-family: 'Crimson Text';
    }

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }

    > div > button {
        display: flex;
        gap: 10px;
        align-items: center;
        font-family: 'Crimson Text';
        padding: 6px 12px;
        border: none;
        border-radius: 3px;
        cursor: pointer;
        background-color: var(--root);
        transition: background-color 0.3s ease-in-out;
        color: white;
        width: fit-content;

        &:hover {
            background-color: #f2f2f2;
            color: var(--root);
        }

        > .MuiSvgIcon-root {
            margin-left: auto;
        }
    }
`