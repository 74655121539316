import React, { useEffect } from 'react'
import styled from 'styled-components'
import fr from '../../../media/francesca_2.png'
import ah from '../../../media/alv-2.png'
import { posts } from '../posts'
import { Helmet } from 'react-helmet-async';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import img1 from '../../../media/blog/b2bcamp_1.jpeg'
import img2 from '../../../media/blog/b2bcamp_2.jpeg'
import img3 from '../../../media/blog/b2bcamp_3.jpeg'


function HowToCreateWinningB2BSEOCampaign({ sWidth, title, desc, author, date }) {
    const category = 'seo'


      useEffect(()=> {
        const schemaData = {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": title,
            "description": desc,
            "author": {
                "@type": "Person",
                "name": author
            },
            "datePublished": date,
            "url": `https://improwth.com/blog/${title.toLowerCase().replaceAll(' ', '-')}`
        };

        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.text = JSON.stringify(schemaData);
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
      }, [])

  return (
    <>
    <Helmet>
        <meta name="description" content="Learn how to create a winning B2B SEO campaign with actionable strategies and insights. Discover how MarketLab's tools can help you optimize your B2B SEO efforts and drive qualified leads." />
        <title>How to Create a Winning B2B SEO Campaign | A Step-by-Step Guide</title>
        <link rel="canonical" href={`https://improwth.com/blog/${title.toLowerCase().replaceAll(' ', '-')}`} />
    </Helmet>
    {sWidth > 768 ? <Container>
        <LeftSection>
            <h3>Recent posts</h3>

            {posts?.sort((a, b)=> b.n - a.n).map(p=> {
                return (<p onClick={()=> window.location.href = `/blog/${p.title.toLowerCase().replaceAll(' ', '-')}`}>{p.title}</p>)
            })}
        </LeftSection>

        <MedSection>
            <div className='catDiv'>
                {category.split('&').map(cat=> {
                    return (<label onClick={()=> window.location.href = `/blog/category/${cat.replaceAll(' ', '-')}`} style={{textTransform: cat === 'seo'? 'uppercase': 'capitalize'}}>{cat}</label>)
                })}
            </div>
            <h1>{title}</h1>

            <div>
                <h4>By <span onClick={()=> window.location.href = `/authors/${author.toLowerCase().replaceAll(' ', '-')}`}>{author}</span></h4>
                <p>{date}</p>
            </div>

            <p>
        Building a successful B2B SEO campaign requires a mix of strategic planning, 
        data analysis, and continuous optimization. With a focus on generating 
        qualified leads, B2B SEO needs to be tailored to target businesses 
        rather than individual consumers. In this guide, we'll walk you through 
        the steps to create a winning B2B SEO campaign with a continuous example 
        using <span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span> tools.
      </p>

      <h2>1. Identify Your Target Audience</h2>
      <p>
        The first step in any B2B SEO campaign is to identify your target audience. 
        B2B SEO focuses on targeting businesses, so you need to understand the specific 
        industries, companies, and decision-makers you want to reach. 
        For example, let's say you're a software company targeting mid-sized 
        manufacturing firms.
      </p>
      

      <h2>2. Perform Keyword Research</h2>
      <p>
        Next, you need to perform keyword research to identify the search terms 
        your target audience is using. For B2B SEO, it's essential to focus on 
        long-tail keywords that are specific to your industry. 
        For example, instead of targeting broad keywords like "manufacturing software," 
        you might focus on more specific terms like "ERP solutions for mid-sized manufacturers."
      </p>
      <p>
      <span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>'s keyword research tool can help you discover high-volume, 
        low-competition keywords that align with your target audience's needs. 
        For example, you might find keywords like "custom ERP software for manufacturing" 
        and "manufacturing process automation tools."
      </p>

      <img src={img1} alt='keyword research tool' />

      <h2>3. Create High-Quality, Targeted Content</h2>
      <p>
        Content is the backbone of any SEO campaign, and B2B SEO is no exception. 
        Your content should be tailored to address the specific <span style={{backgroundColor: 'lightpink', fontFamily: 'Quicksand'}}>pain points 
        and needs</span> of your target audience. For example, you could create a 
        series of blog posts, whitepapers, and case studies that demonstrate 
        how your ERP software can streamline manufacturing processes and reduce costs.
      </p>
      <div className='a-box'>
      <p><span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>'s content optimization tool can help you ensure that your 
        content is not only relevant but also optimized for search engines. 
        For example, you can use the tool to analyze 
        competitor content, and optimize your headlines and meta descriptions.</p>
      

      <img src={img2} alt='content tools' style={{width: '300px', alignSelf: 'start'}}/>
      </div>

      <h2>4. Optimize On-Page Elements</h2>
      <p>
        On-page optimization is critical to improving your website's search engine rankings. 
        This includes optimizing your title tags, meta descriptions, headings, and 
        internal linking structure. For example, if you're targeting the keyword 
        "manufacturing process automation tools," make sure this phrase is included 
        in your title tag, H1 heading, and throughout your content.
      </p>
      <div className='a-box'>
      <p><span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>'s on-page SEO audit tool can help you identify areas for improvement 
        and ensure that your website is optimized for search engines.</p>
      </div>

      <h2>5. Build High-Quality Backlinks</h2>
      <p>
        Backlinks are a crucial component of any SEO campaign, as they signal to search engines 
        that your website is authoritative and trustworthy. For B2B SEO, focus on building 
        high-quality backlinks from industry-specific websites, blogs, and publications. 
        For example, you could write guest posts for manufacturing industry blogs or 
        partner with industry associations to get your content featured on their websites.
      </p>
      <div className='a-box'>
      <p>
      <span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>'s backlink analysis tool can help you identify opportunities for 
        building high-quality backlinks.
      </p>
      <img src={img3} alt='backlinks tools'  style={{width: '300px', alignSelf: 'start'}}/>
      </div>

      <h2>6. Monitor and Adjust Your Campaign</h2>
      <p>
        SEO is an ongoing process, and it's essential to continuously monitor your campaign's 
        performance and make adjustments as needed. This includes tracking your rankings, 
        analyzing your traffic and conversion rates, and staying up-to-date with 
        changes in search engine algorithms.
      </p>
      <div className='a-box'>
      <p>
      <span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>'s analytics dashboard provides real-time insights into your campaign's 
        performance, allowing you to quickly identify areas for improvement and make 
        data-driven decisions. For example, you might discover that certain keywords 
        are driving more conversions than others, leading you to adjust your content 
        strategy accordingly.
      </p></div>

      <h2>Closing Remarks</h2>
      <p>
        Creating a winning B2B SEO campaign requires a combination of strategic planning, 
        data analysis, and continuous optimization. By following these steps and leveraging 
        MarketLab's tools, you can build a successful B2B SEO campaign that drives 
        qualified leads and helps you achieve your business goals.
      </p>

        </MedSection>

        <RightSection>
            <img src={author === 'Francesca Romano'? fr: author === 'Alvaro Hernandez'? ah: ''} width={100} height={100} alt='author' />
            <h4 onClick={()=> window.location.href = `/authors/${author.toLowerCase().replaceAll(' ', '-')}`}>{author}</h4>
            <p>Francesca is an author with expertise in digital marketing, SEO, and SEM. Her writings offer valuable insights into the latest strategies and trends in the digital marketing landscape, helping professionals navigate the complexities of online visibility and search engine performance.</p>
        </RightSection>
    </Container>:
    
    <MContainer>
        <div style={{width: '100%'}}>

        <div className='catDiv'>
                {category.split('&').map(cat=> {
                    return (<label onClick={()=> window.location.href = `/blog/category/${cat.replaceAll(' ', '-')}`} style={{textTransform: cat === 'seo'? 'uppercase': 'capitalize'}}>{cat}</label>)
                })}
            </div>
            <h1>{title}</h1>

            <div>
                <h4>By <span onClick={()=> window.location.href = `/authors/${author.toLowerCase().replaceAll(' ', '-')}`}>{author}</span></h4>
                <p>{date}</p>
            </div>

            <p>
        Building a successful B2B SEO campaign requires a mix of strategic planning, 
        data analysis, and continuous optimization. With a focus on generating 
        qualified leads, B2B SEO needs to be tailored to target businesses 
        rather than individual consumers. In this guide, we'll walk you through 
        the steps to create a winning B2B SEO campaign with a continuous example 
        using <span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span> tools.
      </p>

      <h2>1. Identify Your Target Audience</h2>
      <p>
        The first step in any B2B SEO campaign is to identify your target audience. 
        B2B SEO focuses on targeting businesses, so you need to understand the specific 
        industries, companies, and decision-makers you want to reach. 
        For example, let's say you're a software company targeting mid-sized 
        manufacturing firms.
      </p>
      

      <h2>2. Perform Keyword Research</h2>
      <p>
        Next, you need to perform keyword research to identify the search terms 
        your target audience is using. For B2B SEO, it's essential to focus on 
        long-tail keywords that are specific to your industry. 
        For example, instead of targeting broad keywords like "manufacturing software," 
        you might focus on more specific terms like "ERP solutions for mid-sized manufacturers."
      </p>
      <p>
      <span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>'s keyword research tool can help you discover high-volume, 
        low-competition keywords that align with your target audience's needs. 
        For example, you might find keywords like "custom ERP software for manufacturing" 
        and "manufacturing process automation tools."
      </p>

      <img src={img1} alt='keyword research tool' />

      <h2>3. Create High-Quality, Targeted Content</h2>
      <p>
        Content is the backbone of any SEO campaign, and B2B SEO is no exception. 
        Your content should be tailored to address the specific <span style={{backgroundColor: 'lightpink', fontFamily: 'Quicksand'}}>pain points 
        and needs</span> of your target audience. For example, you could create a 
        series of blog posts, whitepapers, and case studies that demonstrate 
        how your ERP software can streamline manufacturing processes and reduce costs.
      </p>
      <div className='a-box'>
      <p><span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>'s content optimization tool can help you ensure that your 
        content is not only relevant but also optimized for search engines. 
        For example, you can use the tool to analyze 
        competitor content, and optimize your headlines and meta descriptions.</p>
      

      <img src={img2} alt='content tools' style={{width: '300px', alignSelf: 'start'}}/>
      </div>

      <h2>4. Optimize On-Page Elements</h2>
      <p>
        On-page optimization is critical to improving your website's search engine rankings. 
        This includes optimizing your title tags, meta descriptions, headings, and 
        internal linking structure. For example, if you're targeting the keyword 
        "manufacturing process automation tools," make sure this phrase is included 
        in your title tag, H1 heading, and throughout your content.
      </p>
      <div className='a-box'>
      <p><span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>'s on-page SEO audit tool can help you identify areas for improvement 
        and ensure that your website is optimized for search engines.</p>
      </div>

      <h2>5. Build High-Quality Backlinks</h2>
      <p>
        Backlinks are a crucial component of any SEO campaign, as they signal to search engines 
        that your website is authoritative and trustworthy. For B2B SEO, focus on building 
        high-quality backlinks from industry-specific websites, blogs, and publications. 
        For example, you could write guest posts for manufacturing industry blogs or 
        partner with industry associations to get your content featured on their websites.
      </p>
      <div className='a-box'>
      <p>
      <span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>'s backlink analysis tool can help you identify opportunities for 
        building high-quality backlinks.
      </p>
      <img src={img3} alt='backlinks tools'  style={{width: '300px', alignSelf: 'start'}}/>
      </div>

      <h2>6. Monitor and Adjust Your Campaign</h2>
      <p>
        SEO is an ongoing process, and it's essential to continuously monitor your campaign's 
        performance and make adjustments as needed. This includes tracking your rankings, 
        analyzing your traffic and conversion rates, and staying up-to-date with 
        changes in search engine algorithms.
      </p>
      <div className='a-box'>
      <p>
      <span style={{color: 'blue', fontFamily: 'Quicksand', cursor: 'pointer'}} onClick={()=> window.open('https://improwth.com/market-lab', '_blank')}>MarketLab</span>'s analytics dashboard provides real-time insights into your campaign's 
        performance, allowing you to quickly identify areas for improvement and make 
        data-driven decisions. For example, you might discover that certain keywords 
        are driving more conversions than others, leading you to adjust your content 
        strategy accordingly.
      </p></div>

      <h2>Closing Remarks</h2>
      <p>
        Creating a winning B2B SEO campaign requires a combination of strategic planning, 
        data analysis, and continuous optimization. By following these steps and leveraging 
        MarketLab's tools, you can build a successful B2B SEO campaign that drives 
        qualified leads and helps you achieve your business goals.
      </p>

        </div>
    </MContainer>
    }

    </>
  )
}

export default HowToCreateWinningB2BSEOCampaign


const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 25px;
    height: auto;
    width: 100vw;
`


const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 20%;
    border-right: 1px solid lightgray;
    margin-top: 20px;

    > h3 {
        font-weight: 500;
        font-family: 'Quicksand';
        margin-top: 70px;
        margin-left: 10px;
    }

    > p {
        font-family: 'Quicksand';
        margin-top: 20px;
        margin-left: 10px;
        color: blue;
        cursor: pointer;
        transition: color 0.3s ease-in-out;

        &:hover {
            color: dodgerblue;
        }
    }
`


const MedSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: center;
    height: fit-content;
    padding-bottom: 200px;

    > .catDiv {
        width: 100%;
        margin-top: 35px;
        margin-bottom: 0px;
        display: flex;
        gap: 10px;

        > label {
            color: gray;
            transition: color 0.3s ease-in-out;
            cursor: pointer;
            font-family: 'Quicksand';
            text-transform: capitalize;

            &:hover {
                color: blue;
            }
        }
    }
        

    > h1 {
        font-weight: 400;
        font-family: 'Quicksand';
        margin-top: 10px;
        font-size: 55px;
        margin-bottom: 100px;
        color: blue;
        text-align: center;
    }

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        width: 400px;
        margin-bottom: 70px;

        > img {
            border-radius: 50%;
        }

        > h4 {
            font-weight: 400;
            
            > span {
                font-family: 'Quicksand';
                color: blue;
                cursor: pointer;
                transition: color 0.3s ease-in-out;

                &:hover {
                    color: dodgerblue;
                }
            }
        }

        > p {
            font-family: 'Quicksand';
            margin-left: auto;
            margin-right: 7px;
        }
    }

    > h2 {
        font-weight: 400;
        font-family: 'Quicksand';
        margin-top: 35px;
        margin-left: 10px;
        text-align: left;
        width: 100%;
        font-size: 35px;
    }

    > h3 {
        font-weight: normal;
        font-family: 'Quicksand';
        margin-top: 35px;
        margin-left: 10px;
        text-align: left;
        width: 100%;
        color: blue;
        margin-bottom: 20px;
        margin-top: 50px;
    }

    > p {
        font-family: 'Quicksand';
        margin-top: 35px;
        text-align: left;
        width: 100%;
        /* margin-left: 10px; */
        margin-bottom: 30px;
        font-size: 20px;
        line-height: 1.6;
    }

    > img {
        width: 1000px;
        align-self: self-start;
        max-width: 1000px; 
        border-radius: 3px;
        object-fit: contain;
        margin-bottom: 70px;
    }

    > ul {
        list-style-type: none;
        margin-left: 0;

        > img {
        width: 100%;
        align-self: self-start;
        max-width: 1000px; 
        border-radius: 3px;
        object-fit: contain;
        margin-bottom: 50px;
    }
    }

    > ul > li {
        font-family: 'Quicksand';
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;
        margin-left: 0;
        gap: 10px;
        font-size: 20px;

        > div {
            display: flex;
            align-items: center;
            gap: 7px;
        

            > strong {
                font-family: 'Quicksand';
                width: fit-content;
            }

            > .MuiSvgIcon-root {
                font-size: small;
            }
        }

        > p {
            font-family: 'Quicksand';
            margin-left: 10px;
        }
    }


    > .a-box {
        display: flex;
        flex-direction: column;
        padding: 10px;
        border: 1px solid lightgray;
        border-radius: 5px;
        background-color: #ccf0fcc0;
        width: 800px;
        align-self: flex-start;

        > p {
            color: gray;
        }

        > div {
            display: flex;
            justify-content: space-around;
            width: 100%;
            gap: 10px;

            > img {
            object-fit: contain;
            border-radius: 3px;
            border: 1px solid lightgray;
            }
        }

        > img {
            object-fit: contain;
            border-radius: 3px;
            width: 800px;
            border: 1px solid lightgray;
            }
    }
`


const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 20%;
    margin-right: 20px;
    align-items: center;
    

    > img {
            border-radius: 50%;
            margin-top: 130px;
            object-fit: contain;
        }

        > h4 {
            font-weight: 400;
            font-family: 'Quicksand';
            color: blue;
            cursor: pointer;
            transition: color 0.3s ease-in-out;
            margin-top: 20px;
            text-align: left;
            width: 100%;

            &:hover {
                color: dodgerblue;
            }
        }

        > p {
            font-family: 'Quicksand';
            margin-top: 20px;
            margin-right: 10px;
        }

        > .MuiSvgIcon-root {
            cursor: pointer;
            color: blue;
            transition: color 0.3s ease-in-out;
            align-self: start;
            margin-top: 10px;

            &:hover {
                color: dodgerblue;
            }
        }
`



const MContainer = styled.div`
    display: flex;


    > div {
        display: flex;
        flex-direction: column;

        > ul {
            > li {
                margin-left: 10px;
                line-height: 2rem;
            }

            > img {
                width: 100%;
                align-self: self-start;
                max-width: 1000px; 
                border-radius: 3px;
                object-fit: contain;
                margin-bottom: 50px;
            }
        }

        > .catDiv {
            width: 60%;
            margin-top: 60px;
            display: flex;
            align-items: center;
            gap: 10px;

            > label {
                font-family: 'Quicksand';
                color: gray;
                width: fit-content;
            }
        }

        > h1 {
            font-family: 'Quicksand';
            font-weight: 400;
            color: blue;
            font-size: 30px;
            margin-bottom: 30px;
            width: 95%;
            margin-left: 10px;
        }

        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 5px;
            justify-content: space-between;
            margin-bottom: 60px;

            > h4 {
                font-family: 'Quicksand';
                font-weight: 400;
                margin-left: 10px;

                > span {
                    font-family: 'Quicksand';
                    color: blue;
                }
            }

            > p {
                margin-right: 10px;
                font-family: 'Quicksand';
            }
        }

        > h2 {
            font-family: 'Quicksand';
            font-weight: 400;
            margin-left: 10px;
            width: 90%;
        }

        > h3 {
            font-weight: normal;
            font-family: 'Quicksand';
            margin-top: 35px;
            margin-left: 10px;
            text-align: left;
            width: 100%;
            color: blue;
            margin-bottom: 20px;
            margin-top: 50px;
        }

        > p {
            font-family: 'Quicksand';
            margin-left: 10px;
            width: 90%;
            margin-top: 30px;
            margin-bottom: 40px;
        }
    }

    > div > .a-box {
        display: flex;
        flex-direction: column !important;
        padding: 10px;
        border: 1px solid lightgray;
        border-radius: 5px;
        background-color: #ccf0fcc0;
        width: 98% !important;
        padding-right: 20px;
        /* align-self: flex-start; */
        align-items: start;
        gap: 10px;

        > p {
            color: gray;
            width: 95%;
            word-wrap: break-word;
        }

        > div {
            display: flex;
            justify-content: space-around;
            width: 100%;
            gap: 10px;

            > img {
            object-fit: contain;
            border-radius: 3px;
            border: 1px solid lightgray;
            }
        }

        > img {
            object-fit: contain;
            border-radius: 3px;
            /* width: 800px; */
            border: 1px solid lightgray;
            width: 95%;
            }
    }
`